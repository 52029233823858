import axios from "axios";
import { ErrorMessageHandling } from "../../components/error";
import CONFIG from "../../config";
import { messages } from "../products/constant";
import Alert from "../../components/alert";

export const paypal_allowed_countries = [
  "AUD",
  "BRL",
  "CAD",
  "CNY",
  "CZK",
  "DKK",
  "EUR",
  "HKD",
  "HUF",
  "ILS",
  "JPY",
  "MYR",
  "MXN",
  "TWD",
  "NZD",
  "NOK",
  "PHP",
  "PLN",
  "GBP",
  "RUB",
  "SGD",
  "SEK",
  "CHF",
  "THB",
  "USD",
];

export const getPaymentGatewayChecking = async (
  props,
  paymentButtonShow,
  updateData
) => {
  const { institute_id, student_auth } = props;
  let url =
    CONFIG.BASE_URL +
    `/api/v1/payment-gateway/${institute_id}?is_updated_payment_req=true`;
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: student_auth,
      Source: "WEB",
    },
  };
  axios.get(url, headers).then(async (res) => {
    if (res?.status == 200) {
      if (Object.keys(res?.data?.result).length > 0) {
        paymentButtonShow(true);
        updateData(res?.data?.result?.Razorpay);
      }
    }
  });
};

export const getPaymentGateway = async (props) => {
  console.log(props, "checkprops");
  const {
    student_auth,
    setLoader,
    useAtom,
    useRazorpay,
    institute_id,
    country_id,
    currency_code,
    amount,
    usePaypal,
    message,
    dispatch,
    country_details,
    select_payment_gateway,
  } = props;
  let url =
    CONFIG.BASE_URL +
    `/api/v1/payment-gateway/${institute_id}?is_updated_payment_req=true`;
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: student_auth,
      Source: "WEB",
    },
  };

  axios
    .get(url, headers)
    .then(async (res) => {
      setLoader(false);
      if (res.status == 200 && res.data.statusCode == 200) {
        console.log("innnnnnnnnnnnnnnnnnnnn");
        if (Object.keys(res?.data?.result).length == 0) {
          Alert(
            "We apologize, but our online payment processing is currently unavailable.For assistance, please contact your institute. Thank You!"
          );
        }
        if (currency_code == "INR") {
          if (
            res.data.result.Atom &&
            res.data.result.Atom.is_active == "Y" &&
            select_payment_gateway == "atom"
          ) {
            //use atome
            useAtom(
              res.data.result.Atom.is_proctur_payment_gateway == "Y"
                ? "Proctur"
                : "Client",
              res.data.result.Atom?.merchant_id,
              res.data.result.Atom?.email_id
            );
          } else {
            //use razorpay
            // useAtom(
            //   res.data.result.Atom.is_proctur_payment_gateway == "Y"
            //     ? "Proctur"
            //     : "Client"
            // );
            useRazorpay();
          }
        } else if (
          paypal_allowed_countries.includes(currency_code) &&
          res.data.result.PayPal
        ) {
          //use paypal
          usePaypal(
            res.data.result.PayPal.is_proctur_payment_gateway == "Y"
              ? "Proctur"
              : "Client"
          );
        } else {
          message("e", "You are not allowed to do payment.");
        }
      } else if (
        currency_code == "INR" &&
        country_details.currency_code == "INR"
      ) {
        //use razorpay
        useRazorpay();
      } else {
        message("e", res.data.message);
      }
    })
    .catch((err) => {
      setLoader(false);
      if (country_details.currency_code == "INR" && currency_code == "INR") {
        //use razorpay
        useRazorpay();
      } else if (err && err.response && err.response.status == 403) {
        dispatch({
          type: "LOGOUT",
          message:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "",
        });
      } else {
        message("e", ErrorMessageHandling(err));
      }
    });
};

export const getAtomPaymentURL = async (payload, setLoader) => {
  setLoader(true);
  let flag = false,
    url = "";
  await axios
    .post(`${CONFIG.BASE_URL}/api/v1/payment-gateway/payment/token`, payload, {
      headers: { Source: "WEB" },
    })
    .then((res) => {
      setLoader(false);
      if (
        res.status == 200 &&
        res.data.statusCode == 200 &&
        res.data &&
        res.data !== "" &&
        res.data.result &&
        res.data.result !== ""
      ) {
        flag = true;
        url = res.data.result;
      } else if (
        res.status == 200 &&
        res.data.statusCode == 200 &&
        res.data &&
        res.data.result
      ) {
        flag = false;
        alert(res.data.message);
      } else if (
        res.status == 200 &&
        res.data.statusCode == 200 &&
        res.data &&
        res.data == ""
      ) {
        flag = false;
        alert(messages.unableToPay);
      }
    })
    .catch((err) => {
      setLoader(false);

      if (err.response && err.response.message) {
        alert(err.response.message);
      } else {
        alert(messages.unableToPay);
      }
      flag = false;
    });
  if (flag) {
    return url;
  } else {
    return "";
  }
};

export const getPaypalPaymentURL = async (props) => {
  const { payload, student_auth, setLoader } = props;
  setLoader(true);
  let flag = false,
    url = "";
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: student_auth,
      Source: "WEB",
    },
  };
  await axios
    .post(
      `${CONFIG.BASE_URL}/api/v1/payment-gateway/paypal/payment`,
      payload,
      headers
    )
    .then((res) => {
      setLoader(false);

      if (
        res.status == 200 &&
        res.data.statusCode == 200 &&
        res.data &&
        res.data !== "" &&
        res.data.result &&
        res.data.result !== ""
      ) {
        flag = true;
        url = res.data.result;
      } else if (
        res.status == 200 &&
        res.data.statusCode == 200 &&
        res.data &&
        res.data.result
      ) {
        flag = false;
        alert(res.data.message);
      } else if (
        res.status == 200 &&
        res.data.statusCode == 200 &&
        res.data &&
        res.data == ""
      ) {
        flag = false;
        alert(messages.unableToPay);
      }
    })
    .catch((err) => {
      setLoader(false);
      if (err.response && err.response.data && err.response.data.message) {
        alert(err.response.data.message);
      } else {
        alert(messages.unableToPay);
      }
      flag = false;
    });
  if (flag) {
    return url;
  } else {
    return "";
  }
};

export const getPaymentType = async (
  props,
  opnAlrtPopForNotIntegratePayGat
) => {
  console.log(props, "checkpropshere");
  const {
    student_auth,
    setLoader,
    institute_id,
    currency_code,
    amount,
    message,
    dispatch,
    country_details,
    setType,
    updateData,
  } = props;
  let url =
    CONFIG.BASE_URL +
    `/api/v1/payment-gateway/${institute_id}?is_updated_payment_req=true`;
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: student_auth,
      Source: "WEB",
    },
  };
  axios
    .get(url, headers)
    .then(async (res) => {
      setLoader(false);
      if (res.status == 200 && res.data.statusCode == 200) {
        console.log("innnnnnnnnnnnnnnnnnnnnuuuuuuuuuuuuuuuuu");
        if (Object.keys(res?.data?.result).length == 0) {
          console.log("herererer");
          opnAlrtPopForNotIntegratePayGat();
        }
        if (currency_code == "INR") {
          if (res.data.result.Atom && res.data.result.Razorpay) {
            setType("both");
          } else if (
            res.data.result.Atom &&
            res.data.result.Atom.is_active == "Y"
          ) {
            //use atome

            setType("atom");
          } else if (res.data.result.Razorpay) {
            //use razorpay
            updateData(res.data.result.Razorpay);
            setType("razorpay");
          }
        } else if (
          paypal_allowed_countries.includes(currency_code) &&
          res.data.result.PayPal
        ) {
          //use paypal
          setType("paypal");
        } else {
          message("e", "You are not allowed to do payment.");
        }
      } else if (
        currency_code == "INR" &&
        country_details.currency_code == "INR"
      ) {
        //use razorpay
        // setType("razorpay");
      } else {
        message("e", res.data.message);
      }
    })
    .catch((err) => {
      setLoader(false);
      if (country_details.currency_code == "INR" && currency_code == "INR") {
        //use razorpay
        // setType("razorpay");
      } else if (err && err.response && err.response.status == 403) {
        dispatch({
          type: "LOGOUT",
          message:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "",
        });
      } else {
        message("e", ErrorMessageHandling(err));
      }
    });
};
