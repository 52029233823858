import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ExtraCharge = (props) => {
    const { handleClose, handleOk, show_extra_charge_pop_up, total_amount, discount, amount_with_charge, extra_charge, currency_code } = props;
    const showHideClassName = show_extra_charge_pop_up ? "alert display-block" : "alert display-none";
    return (
        <div className={showHideClassName}>
            <section className="alert-main" style={{ maxHeight: window.innerHeight - 200 }} >
                <h4>Amount to Pay</h4>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '20px 6px 0px' }} >
                    <h5>Amount</h5>
                    <h5>{(total_amount - discount).toFixed(2)} {currency_code}</h5>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0px 6px', alignItems: 'center' }} >

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                        <h5 style={{ padding: 0 }} >Conv. Fees </h5>
                        <div>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id={`tooltip-right`} style={{ backgroundColor: 'gray', fontSize: 10, padding: 4, color: 'white' }} >
                                        Online Payment Handling Charges
                                    </Tooltip>
                                }
                            >
                                <i style={{ fontSize: 16, paddingLeft: 4 }} className='fa fa-info-circle' ></i>
                            </OverlayTrigger>
                        </div>
                    </div>

                    <h5>{extra_charge.toFixed(2)} {currency_code}</h5>
                </div>


                <div style={{ width: '98%', height: 0.1, backgroundColor: 'gray', marginLeft: '6px' }} />

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '10px 6px 0px' }} >
                    <h5 style={{ fontWeight: 'bold' }} >Total Amount</h5>

                    <h5 style={{ fontWeight: 'bold' }} >{amount_with_charge} {currency_code}</h5>
                </div>

                <div className="buttonContainer" >
                    <button onClick={handleClose} className="cancel">Close</button>
                    <button onClick={handleOk} className="ok">OK</button>
                </div>
            </section>
        </div>
    );
};

export default ExtraCharge;
