// PaymentGatewayModal.js
import React, { useEffect, useState } from "react";
import Razorepay from "../../assets/paymenttype/razorepay.png";
import Paypal from "../../assets/paymenttype/paypal.png";
import Atom from "../../assets/paymenttype/atom.png";
import "./paymentGatewaymodal.css";
const PaymentGatewayModal = (props) => {
  const [extraCharge, setExtraCharge] = useState(0);
  const [totalAmount, setTotalAmmount] = useState(0);

  const calculateExtraChrge = () => {
    let amount = props.total_amount - props.discount;
    if (props.enable_razorpay_gst_fee_from_student == 1 && amount > 0) {
      let extra_charge =
        (Number(amount) * Number(props.razor_payment_gateway_percentage_fee)) /
        100;
      let amount_with_charge =
        Number(amount) + extra_charge + (extra_charge * 18) / 100;
      amount_with_charge = amount_with_charge.toFixed(2);
      setExtraCharge(amount_with_charge - amount);
      setTotalAmmount(amount_with_charge);
    }
  };

  const calculatedAtomTotalAmount = () => {
    let amount = props.total_amount - props.discount;
    return Number(amount).toFixed(2);
  };
  useEffect(() => {
    calculateExtraChrge();
  }, []);
  return (
    <div className={`custom-modal }`}>
      <div className="modal-content">
        <span
          className="close"
          onClick={() => {
            props.handleClose();
          }}
        >
          &times;
        </span>
        <h2 style={{ padding: "12px" }}>Select Payment Gateway</h2>
        <div className="lineHor"></div>
        <div
          className="modal-row"
          onClick={() => {
            props.changePaymentType("razorpay");
          }}
        >
          <div className="logo">
            <img src={Razorepay} alt={Razorepay} />
          </div>
          <div className="details">
            <p>Amount</p>
            <p>Conv. Fees</p>
            <p style={{ fontWeight: "700" }}>Total Amount</p>
          </div>
          <div className="amount">
            <p>
              <span>Rs.</span> {props?.total_amount.toFixed(2)}
            </p>
            <p>
              {" "}
              <span>Rs.</span> {extraCharge.toFixed(2)}
            </p>
            <p>
              {" "}
              <span>Rs.</span>{" "}
              <span style={{ fontWeight: "700" }}>{totalAmount}</span>
            </p>
          </div>

          {/* Add a horizontal line except for the last row */}
        </div>
        <div className="lineHor"></div>
        <div
          className="modal-row"
          onClick={() => {
            props.changePaymentType("atom");
          }}
        >
          <div className="logo">
            <img src={Atom} alt={Atom} />
          </div>
          <div className="details">
            <p>Amount</p>
          </div>
          <div className="amount">
            <p>
              <span>Rs.</span> {calculatedAtomTotalAmount()}
            </p>
          </div>

          {/* Add a horizontal line except for the last row */}
        </div>
        <div className="lineHor"></div>
        {/* <div className="modal-row">
          <div className="logo">
            <img src={Paypal} alt={Paypal} />
          </div>
          <div className="details">
            <p>Amount</p>
          </div>
          <div className="amount">
            <p>
              <span>Rs.</span> 200
            </p>
          </div>

         
        </div> */}
      </div>
    </div>
  );
};

export default PaymentGatewayModal;
