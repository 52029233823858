import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, withRouter } from "react-router-dom";
import HELPERS from "../../utils/helper";
import apis from "../../config/apis";
import CONFIG from "../../config";
import { connect } from "react-redux";
import { ToastMessage, Toast, toastMsgConfig } from "../../components/toast";
import { messages } from "./constant";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      disabled: false,
      step_key: "info",

      courses: [],
      course_ids: [],
      courses_flag: [],

      name: "",
      mobile_number: "",
      email_id: "",
      pass: "",
      confirm_pass: "",

      otp: ["", "", "", ""],
      timer: 0,

      selected_country: "",
      country_codes: [],

      accept_terms: true,

      sub_branches: [],
      selected_branch: { institute_id: 0, institute_name: "Select Branch" },
      show_select_branch: false,
    };
  }

  getCourses = async () => {
    let headers = {
      headers: { Authorization: this.props.access_token.auth_token },
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.GET_COURSES +
          this.props.institute_id +
          "?data_for_registeration=true",
        {},
        "GET",
        "proctur",
        headers
      );
      if (res.status == 200) {
        this.setState({
          courses: res.data,
          courses_flag: Array(res.data.length).fill(false),
          loader: false,
        });
      }
    } catch (error) {
      if (error && error.message) {
        this.message("e", error.message);
      } else {
        this.message("e", "Something went wrong, Try after some time.");
      }
      this.setState({ loader: false });
    }
  };

  getCountryCodes = async () => {
    let headers = {
      headers: { Authorization: this.props.access_token.auth_token },
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.GET_COUNTRY_CODES + this.props.institute_id,
        {},
        "GET",
        "proctur",
        headers
      );
      if (res.status == 200) {
        let selected_country = res.data.find((el) => {
          return el.is_default == "Y";
        });
        if (
          selected_country &&
          selected_country.id == undefined &&
          res.data.length > 0
        ) {
          selected_country = res.data[0];
        } else if (selected_country && selected_country.id == undefined) {
          selected_country = {};
        }
        this.setState({
          country_codes: res.data,
          selected_country: selected_country,
        });
      } else {
        this.message("e", "Unable to fetch country code");
      }
    } catch (error) {
      this.message("e", "Unable to fetch country code");
    }
  };

  message = (type, message) => {
    Toast(message, toastMsgConfig(type));
  };

  componentDidMount = () => {
    let url = window.location.href.replace('register','') ;
        this.getInstituteId(url);
  };

  getWebAccessToken = async () => {
    let res = await HELPERS.ApiCall(
      apis.WEB_ACCESS_TOKEN + this.props.institute_id,
      {},
      "GET",
      "proctur",
      {}
    );
    if (res.status == 202 && res.data.statusCode == 200) {
      this.props.dispatch({
        type: "SET_ACCESS_TOKEN",
        access_token: res.data.result,
      });
      this.props.dispatch({
        type: "UPDATE_REGISTRATION_INSTITUTE",
        data: res.data.result,
      });
    }
  };
  getInstituteId = async (url) => {
    try {
      let res = await HELPERS.ApiCall(
        apis.GET_INSTITUTE_ID + url.replace("www.", ""),
        {},
        "GET",
        "proctur",
        {}
      );
      if (res.status == 200 && res.data && res.data.institute_id) {
        this.props.dispatch({
          type: "SET_INSTITUTE_ID",
          institute_id: res.data.institute_id,
        });
        await this.props.dispatch({
          type: "SET_INSTITUTE_NAME",
          institute_name: res.data.institute_name,
          website_enable:
            res.data.website_enable == undefined ||
            res.data.website_enable == null
              ? true
              : res.data.website_enable,
        });
        await this.props.dispatch({
          type: "SET_SITE_DATA",
          site_data: res.data,
        });
        document.title = res.data.institute_name;
        await this.getWebAccessToken();
        this.getCourses();
        this.getCountryCodes();
        this.getSubBranch();
      }else{
      this.message("e","You do not have access to the registration process. Please contact the Institute Administrator for assistance.");
        setTimeout(() => {
          window.location = window.origin;
        }, 5000);
      }
    } catch (err) {
      if (err && err.response && err.response.message) {
        this.message("e", err.response.message);
      }
      this.setState({ load_data: false, loader: false });
    }
  };

  validateInfo = () => {
    this.setState({ disabled: true });
    setTimeout(() => {
      this.setState({ disabled: false });
    }, 2000);
    const {
      name,
      mobile_number,
      email_id,
      pass,
      confirm_pass,
      selected_country,
      accept_terms,
      selected_branch,
      show_select_branch,
    } = this.state;
    if (name.trim().length === 0) {
      this.message("e", messages.invalid_name_length);
    } else if (mobile_number.trim().length === 0) {
      this.message("e", messages.invalid_number_length);
    } else if (
      selected_country &&
      selected_country.country_calling_code &&
      selected_country.country_calling_code == "+91" &&
      mobile_number.trim().length !== 10
    ) {
      this.message("e", "Please enter valid 10 digit number.");
    } else if (email_id.trim().length === 0) {
      this.message("e", messages.invalid_email_length);
    } else if (!HELPERS.validateEmail(email_id)) {
      this.message("e", messages.invalid_email);
    } else if (!HELPERS.validatePass(pass.trim())) {
      this.message("e", messages.invalid_pass_length);
    } else if (pass !== confirm_pass) {
      this.message("e", messages.invalid_confirm_pass);
    } else if (!accept_terms) {
      this.message("e", "Please accept terms and condition for proceed!");
    }
    // else if (show_select_branch && selected_branch.institute_id == 0) {
    //   this.message('e', 'Please select branch!');
    // }
    else {
      window.scrollTo(0, 0);
      this.setState({ step_key: "course" });
    }
  };

  generateOTP = async () => {
    this.setState({ loader: true, disabled: true });
    setTimeout(() => {
      this.setState({ disabled: false });
    }, 3000);

    let headers = {
      headers: {
        Authorization: this.props.registration_institute.third_party_auth_token,
      },
    };
    let payload = {
      user_name: this.state.mobile_number,
      source: "register",
      app_hash_code: "",
      role: 99,
      email_id: this.state.email_id,
    };
    if (this.state.country_codes.length > 0) {
      payload["country_id"] = this.state.selected_country.id;
    }
    try {
      let res = await HELPERS.ApiCall(
        apis.GENERATE_OTP,
        payload,
        "POST",
        "proctur",
        headers
      );
      if (res.data && res.data.statusCode == 200) {
        if (res.data.result.skip_otp_verification) {
          this.registerStudent();
        } else {
          this.message("s", res.data.message);
          if (this.state.step_key !== "otp") {
            window.scrollTo(0, 0);
            this.setState({ step_key: "otp" });
          }
          this.startTimer(120);
        }
        this.setState({ loader: false });
      } else {
        this.message("e", messages.otp_generation_failed);
        this.setState({ loader: false });
      }
    } catch (error) {
      this.setState({ loader: false });
      if (error.response && error.response.message) {
        this.message("e", error.response.message);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        this.message("e", error.response.data.error[0].errorMessage);
      } else if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.error) &&
        error.response.data.error.length > 0
      ) {
        this.message("e", error.response.data.error[0].errorMessage);
      } else {
        this.message("e", error.message);
      }
    }
  };

  validateOTP = async () => {
    this.setState({ loader: true, disabled: true });
    setTimeout(() => {
      this.setState({ disabled: false });
    }, 3000);

    let headers = {
      headers: {
        Authorization: this.props.registration_institute.third_party_auth_token,
      },
    };
    let otpString =
      this.state.otp[0] +
      this.state.otp[1] +
      this.state.otp[2] +
      this.state.otp[3];
    let otp = otpString.replace(/\s/g, "");
    let payload = {
      user_name: this.state.mobile_number,
      otp_code: otp,
      source: "register",
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.VALIDATE_OTP,
        payload,
        "POST",
        "proctur",
        headers
      );
      if (res.status == 201 && res.data.statusCode == 200) {
        this.message("s", "OTP validate successfully");
        this.registerStudent();
      } else {
        this.message("e", messages.otp_validation_failed);
      }
      this.setState({ loader: false });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        this.message("e", error.response.data.message);
      } else if (error.response && error.response.message) {
        this.message("e", error.response.message);
      } else {
        this.message("e", messages.otp_validation_failed);
      }
      this.setState({ loader: false });
    }
  };

  registerStudent = async () => {
    this.setState({ loader: true });
    let headers = {
      headers: {
        Authorization: this.props.registration_institute.third_party_auth_token,
      },
    };
    let payload = {
      name: this.state.name,
      email_id: this.state.email_id,
      password: btoa(this.state.pass),
      mobile_no: this.state.mobile_number,
      eCourseIdArray: this.state.course_ids,
      login_source: "WEB",
      device_id: null,
    };
    if (this.state.country_codes.length > 0) {
      payload["country_id"] = this.state.selected_country.id;
    }
    let student_auth;
    try {
      let res = await HELPERS.ApiCall(
        apis.REGISTER,
        payload,
        "POST",
        "proctur",
        headers
      );
      if (res.status == 201 && res.data.statusCode == 200) {
        this.message("s", messages.register_success);
        this.props.dispatch({
          type: "SET_DEVICE_ID",
          device_id: res.data.result.device_id,
        });
        if (res.data.result.single_device_login) {
          student_auth = btoa(
            res.data.result.user_id +
              "|" +
              res.data.result.user_Type +
              ":" +
              res.data.result.password +
              ":" +
              res.data.result.institute_id +
              ":" +
              res.data.result.device_id +
              ":" +
              "WEB"
          );
        } else {
          student_auth = btoa(
            res.data.result.user_id +
              "|" +
              res.data.result.user_Type +
              ":" +
              res.data.result.password +
              ":" +
              res.data.result.institute_id
          );
        }
        this.props.dispatch({
          type: "LOGIN",
          user_id: res.data.result.user_id,
          user_info: res.data.result,
          student_auth: student_auth,
          country_details: this.state.selected_country,
          enable_online_payment_feature:
            res.data.result.enable_online_payment_feature,
        });
        this.setState({ loader: false });
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.from_cart &&
          this.props.cart.length > 0
        ) {
          this.props.history.push("/cart");
        } else {
          this.props.history.push("/");
        }
      } else {
        this.message("e", messages.register_fail);
      }
    } catch (error) {
      this.setState({ loader: false });
      if (error.response && error.response.message) {
        this.message("e", error.response.message);
      } else {
        this.message("e", messages.register_fail);
      }
    }
  };

  startTimer = (count) => {
    this.setState({ timer: count });
    this.interval = setInterval(() => {
      this.setState((prevState) => ({ timer: prevState.timer - 1 }));
    }, 1000);
  };

  componentDidUpdate = () => {
    if (this.state.timer == 0) {
      clearInterval(this.interval);
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  getSubBranch = async () => {
    let headers = {
      headers: { Authorization: this.props.access_token.auth_token },
    };
    try {
      let res = await HELPERS.ApiCall(
        apis.GET_SUB_BRANCHES + this.props.institute_id,
        {},
        "GET",
        "proctur",
        headers
      );
      if (res.status == 200) {
        this.setState({
          show_select_branch: true,
          sub_branches: [
            {
              institute_id: this.props.institute_id,
              institute_name: this.props.access_token.institute_name,
            },
            ...res.data,
          ],
        });
      } else {
        this.message(res.data.message);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.status == 400
      ) {
        console.log("Sub branch not allowed");
      } else if (
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        this.message("e", err.response.data.message);
      }
      this.setState({ loader: false });
    }
  };

  onSelectBranch = async (el) => {
    this.setState({ selected_branch: el });
    if (el.institute_id !== 0) {
      let res = await HELPERS.ApiCall(
        apis.WEB_ACCESS_TOKEN + el.institute_id,
        {},
        "GET",
        "proctur",
        {}
      );
      if (res.status == 202 && res.data.statusCode == 200) {
        this.props.dispatch({
          type: "UPDATE_REGISTRATION_INSTITUTE",
          data: res.data.result,
        });
      }
    }
  };

  render() {
    const {
      loader,
      disabled,
      step_key,
      courses,
      courses_flag,
      course_ids,
      mobile_number,
      name,
      pass,
      confirm_pass,
      email_id,
      otp,
      timer,
      selected_country,
      country_codes,
      accept_terms,
      sub_branches,
      show_select_branch,
      selected_branch,
    } = this.state;
    let theme_color = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--theme-color");
    let text_color = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--text-color");
    return (
      <>
        {loader && <div className="loading"></div>}
        <ToastMessage />
        <Header />
        <div className="content bgimg2" style={{ paddingTop: 70 }}>
          <div className="mainContent">
            <div className="block" style={{ width: "80%", paddingBottom: 30 }}>
              <h4>Complete just 3 steps for registration</h4>

              <div className="steps">
                <div className="stepsImage">
                  <i
                    className={
                      step_key !== "info" ? "fa fa-check" : "fa fa-user-circle"
                    }
                    style={{
                      backgroundColor:
                        step_key == "info" ? theme_color : "#fff",
                      color: step_key == "info" ? "#fff" : theme_color,
                    }}
                  ></i>
                  <h6>Personal Information</h6>
                </div>
                <div
                  style={{
                    borderBottom: `2px dashed ${theme_color}`,
                    width: "20%",
                    marginTop: -30,
                  }}
                />
                <div className="stepsImage">
                  <i
                    className={step_key == "otp" ? "fa fa-check" : "fa fa-book"}
                    style={{
                      backgroundColor:
                        step_key == "course" ? theme_color : "#fff",
                      color: step_key == "course" ? "#fff" : theme_color,
                    }}
                  ></i>
                  <h6>Interested In</h6>
                </div>
                <div
                  style={{
                    borderBottom: `2px dashed ${theme_color}`,
                    width: "20%",
                    marginTop: -30,
                  }}
                />
                <div className="stepsImage">
                  <i
                    className={"fa fa-key"}
                    style={{
                      backgroundColor: step_key == "otp" ? theme_color : "#fff",
                      color: step_key == "otp" ? "#fff" : theme_color,
                    }}
                  ></i>
                  <h6>OTP Verification</h6>
                </div>
              </div>

              {step_key == "info" && (
                <div className="stepsBlock">
                  <h5>Please give your personal Information</h5>
                  <div className="horizontalLine" />

                  <label className="inputLabel">
                    Full Name:
                    <input
                      className="loginInput"
                      value={name}
                      type="text"
                      placeholder="Enter Your Name"
                      onChange={(e) => {
                        if (HELPERS.validateName(e.target.value)) {
                          this.setState({ name: e.target.value });
                        }
                      }}
                    />
                  </label>

                  <label className="inputLabel">
                    Mobile Number:
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {country_codes.length > 0 && (
                        <select
                          className="loginInput"
                          style={{
                            width: "28%",
                            marginRight: "2%",
                            padding: 0,
                          }}
                          name={selected_country.country_calling_code}
                          onChange={(e) => {
                            this.setState({
                              selected_country: JSON.parse(e.target.value),
                            });
                          }}
                        >
                          {country_codes.map((item, i) => (
                            <option
                              key={i}
                              value={JSON.stringify(item)}
                              selected={
                                selected_country.country_calling_code ==
                                item.country_calling_code
                              }
                            >
                              + {item.country_calling_code}
                            </option>
                          ))}
                        </select>
                      )}
                      <input
                        className="loginInput"
                        value={mobile_number}
                        placeholder="Enter Mobile Number"
                        autocomplete="off"
                        onChange={(e) => {
                          if (HELPERS.validateMobileNumber(e.target.value)) {
                            this.setState({ mobile_number: e.target.value });
                          }
                        }}
                      />
                    </div>
                  </label>

                  <label className="inputLabel">
                    Email address:
                    <input
                      className="loginInput"
                      value={email_id}
                      placeholder="Enter Email Id"
                      type="email"
                      autocomplete="off"
                      onChange={(e) => {
                        this.setState({ email_id: e.target.value });
                      }}
                    />
                  </label>

                  <label className="inputLabel">
                    Password:
                    <input
                      className="loginInput"
                      type="password"
                      value={pass}
                      placeholder="Enter Password"
                      autocomplete="new-password"
                      onChange={(e) => {
                        this.setState({ pass: e.target.value });
                      }}
                    />
                  </label>

                  <label className="inputLabel">
                    Confirm Password:
                    <input
                      className="loginInput"
                      type="password"
                      value={confirm_pass}
                      placeholder="Enter Confirm Password"
                      onChange={(e) => {
                        this.setState({ confirm_pass: e.target.value });
                      }}
                    />
                  </label>

                  {/* {show_select_branch &&
                    <label className="inputLabel">Select Branch:
                     <select className="loginInput" style={{ width: '100%' }} name={selected_branch.institute_name}
                        onChange={(e) => { this.onSelectBranch(JSON.parse(e.target.value)); }}  >
                        <option key={'select'} value={JSON.stringify({ institute_id: 0, institute_name: 'Select Branch' })} selected={selected_branch.institute_name == 'Select Branch'} >
                          {'Select Branch'}
                        </option>
                        {sub_branches.map((item, i) => (
                          <option key={i} value={JSON.stringify(item)} selected={selected_branch.institute_name == item.institute_name} >
                            {item.institute_name}
                          </option>
                        ))}
                      </select>
                    </label>
                  } */}

                  {this.props.website_enable && (
                    <div className="check">
                      <input
                        type="checkbox"
                        id="register"
                        value={accept_terms}
                        checked={accept_terms}
                        onChange={() => {
                          this.setState({ accept_terms: !accept_terms });
                        }}
                      />
                      <label for="register">
                        {" "}
                        <span style={{ fontSize: 12 }}>
                          By registration you agree to our
                          <Link
                            to={{
                              pathname: `/more/terms-and-condition`,
                              state: {
                                label: "TERMS & CONDITION",
                                slug: "/terms-and-condition",
                                hide_image: true,
                              },
                            }}
                          >
                            {" Terms & Conditions."}
                          </Link>
                        </span>
                      </label>
                      <br />
                    </div>
                  )}
                  <button onClick={this.validateInfo} disabled={disabled}>
                    Continue
                  </button>
                </div>
              )}

              {step_key == "course" && (
                <div className="stepsBlock" style={{ width: "80%" }}>
                  <h5>Please select category/course for proceed.</h5>
                  <div className="horizontalLine" />

                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "45% 45%",
                      justifyContent: "space-between",
                    }}
                  >
                    {courses.map((item, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingBottom: 6,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ marginTop: 2 }}
                          checked={courses_flag[i]}
                          onChange={(e) => {
                            let flag = courses_flag;
                            let ids = course_ids;
                            if (flag[i]) {
                              flag[i] = false;
                              let delete_index = ids.indexOf(
                                item.course_type_id
                              );
                              if (delete_index > -1) {
                                ids.splice(delete_index, 1);
                              }
                            } else {
                              flag[i] = true;
                              ids.push(item.course_type_id);
                            }
                            this.setState({
                              course_ids: ids,
                              courses_flag: flag,
                            });
                          }}
                        />
                        <label className="checkbox">
                          &nbsp;&nbsp;&nbsp;&nbsp;{item.course_type.trim()}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="back"
                      onClick={() => {
                        this.setState({ step_key: "info" });
                        window.scrollTo(0, 0);
                      }}
                    >
                      {" "}
                      {"< "}Back
                    </button>
                    <button onClick={this.generateOTP}>
                      {"Skip & Continue"}
                    </button>
                  </div>
                </div>
              )}

              {step_key == "otp" && (
                <div className="stepsBlock" style={{ width: "70%" }}>
                  <h5>OTP Verification</h5>
                  <div className="horizontalLine" />
                  <h6 style={{ fontWeight: 400, paddingBottom: 10 }}>
                    A One-Time Password has been sent to{" "}
                    <span style={{ fontWeight: 600 }}>{mobile_number}</span>{" "}
                  </h6>

                  {timer > 0 && (
                    <h4>
                      {Math.floor(timer / 60)} : {timer % 60}
                    </h4>
                  )}

                  {otp.map((el, i) => (
                    <input
                      key={i}
                      id={i}
                      className="otpInput"
                      value={otp[i]}
                      maxLength={1}
                      type={"text"}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          let otp_data = otp;
                          otp[i] = e.target.value;
                          this.setState({ otp: otp_data });
                          if (i !== 3 && e.target.value !== "") {
                            document.getElementById(i + 1).focus();
                          } else if (e.target.value == "" && i !== 0) {
                            document.getElementById(i - 1).focus();
                          }
                        }
                      }}
                    />
                  ))}

                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      className="cancel"
                      onClick={this.generateOTP}
                      disabled={timer > 0}
                      style={{ opacity: timer > 0 ? 0.5 : 1 }}
                    >
                      Resend OTP
                    </button>
                    <button
                      className="button"
                      disabled={otp.includes("")}
                      style={{ opacity: otp.includes("") ? 0.5 : 1 }}
                      onClick={this.validateOTP}
                    >
                      Validate
                    </button>
                  </div>
                </div>
              )}
            </div>

            <h5 className="link">
              Already have an account ?
              <Link
                to={{
                  pathname: "/login",
                  state: {
                    from_cart:
                      this.props.location &&
                      this.props.location.state &&
                      this.props.location.state.from_cart
                        ? true
                        : false,
                  },
                }}
              >
                Login here
              </Link>
            </h5>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.auth, ...state.cart };
};

export default connect(mapStateToProps)(withRouter(Register));
