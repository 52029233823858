import { CLIENTS } from "./client";

export const env = "production";
export const default_institute_id =
  env == "test"
    ? 101364
    : env == "production" || env == "apiv2"
    ? 105446
    : env == "sit"
    ? 100593
    : 105446;
export const default_institute_id_name =
  env == "test"
    ? "testbed"
    : env == "production"
    ? "Prod"
    : env == "apiv2"
    ? "APIV2"
    : env == "sit"
    ? "sit"
    : "UAT";
const client = "a5_testbed";
const API = {
  test: {
    PROCTUR_BASE_URL: "https://test999.proctur.com/StdMgmtWebAPI",
    PRODUCT_BASE_URL: "https://devsit.proctur.com/",
    // other info
  },
  production: {
    PROCTUR_BASE_URL: "https://api.proctur.com/StdMgmtWebAPI",
    PRODUCT_BASE_URL: "https://productprod.proctur.com/",
    // other info
  },
  uat: {
    PROCTUR_BASE_URL: "https://uat.proctur.com/StdMgmtWebAPI",
    PRODUCT_BASE_URL: "https://productuat.proctur.com/",
  },
  apiv2: {
    PROCTUR_BASE_URL: "https://apiv2.proctur.com/StdMgmtWebAPI",
    PRODUCT_BASE_URL: "https://devsit.proctur.com/",
  },
  sit: {
    PROCTUR_BASE_URL: "https://apiv2.proctur.com/StdMgmtWebAPI",
    PRODUCT_BASE_URL: "https://devsit.proctur.com/",
  },
};

const KEYS = {
  RAZORPAY_KEY: "rzp_live_pQXvkbWD4oVatb",
  RAZORPAY_TEST_KEY: "rzp_test_2Bnamyh6P2eaRC",
  RAZORPAY_SECRET_KEY: "I4Em3urWryYA5E6OTK95AOQa",
  // other keys
};

const CONFIG = {
  RAZORPAY_KEY: KEYS.RAZORPAY_KEY,
  RAZORPAY_TEST_KEY: KEYS.RAZORPAY_TEST_KEY,
  RAZORPAY_SECRET_KEY: KEYS.RAZORPAY_SECRET_KEY,
  BASE_URL: API[env].PROCTUR_BASE_URL,
  PRODUCT_BASE_URL: API[env].PRODUCT_BASE_URL,
  INSTITURE_ID: CLIENTS[client].institute_id,
  INSTITURE_NAME: CLIENTS[client].institute_name,
  // other keys
};

export default CONFIG;
